import { atom } from 'jotai';

export const proposalThresholdAtom = atom<string | null>(null);
export const quorumNumeratorAtom = atom<string | null>(null);
export const quorumDenominatorAtom = atom<string | null>(null);
export const votingPeriodAtom = atom<string | null>(null);
export const votingDelayAtom = atom<string | null>(null);
export const circulatingSupplyAtom = atom<string>('0');
export const tokenAddressAtom = atom<string | null>(null);
export const timelockAddressAtom = atom<string | null>(null);
export const currentBlockNumberAtom = atom<number | null>(6011180);
export const eventTriggeredAtom = atom(false);
export const fundingQuorumAtom = atom<string | null>(null);
export const constitutionalQuorumRawAtom = atom<string | null>(null);
export const fundingQuorumRawAtom = atom<{
  numerator: string | null;
  denominator: string | null;
}>({
  numerator: null,
  denominator: null,
});
export const rawCirculatingSupplyAtom = atom<string | null>(null);

// constitutionalQuorum
export const constitutionalQuorumAtom = atom((get) => {
  const circulatingSupply = get(circulatingSupplyAtom);
  const circulatingSupplyNum = BigInt((circulatingSupply || '0').replace(/,/g, ''));

  return (circulatingSupplyNum * 5n) / 100n; // 5% of circulating supply
});
